import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { BodyMedium, Title } from '@components/styled/Typography';
import { ReactComponent as CheckmarkIconComponent, } from '@icons/wolfkit-solid/checkmark-with-check-circle-solid.svg';
import Button from '@shared/ui/buttons/Button';
const PrimaryButton = (props) => (_jsx(Button, Object.assign({ variant: 'filled', color: 'primary', size: 'large', fullWidth: true }, props)));
const StepTitle = styled(Title)(props => ({
    fontSize: 24,
    fontWeight: 600,
    lineHeight: `${props.theme.baseSize * 6}px`,
    marginBottom: 54,
}));
const ItemLabel = styled(BodyMedium)(props => ({
    fontSize: 14,
    fontWeight: 500,
    color: props.theme.palette.text.primary,
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
const ElementContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.l,
}));
const StepFormContainer = styled.form(() => ({
    maxWidth: 420,
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingTop: '90px',
}));
const UpperContent = styled.div(() => ({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
}));
const BottomContent = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
}));
const StepContainerElement = styled.div(() => ({
    width: 420,
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));
const StepContainer = ({ upperContent = undefined, bottomContent = undefined, }) => (_jsxs(StepContainerElement, { children: [upperContent ? _jsx(UpperContent, { children: upperContent }) : null, bottomContent ? _jsx(BottomContent, { children: bottomContent }) : null] }));
const StepForm = ({ upperContent = undefined, bottomContent = undefined, onSubmit = undefined, }) => (_jsxs(StepFormContainer, { onSubmit: onSubmit, children: [upperContent ? _jsx(UpperContent, { children: upperContent }) : null, bottomContent ? _jsx(BottomContent, { children: bottomContent }) : null] }));
const CheckmarkIcon = styled(CheckmarkIconComponent)(props => ({
    width: `${props.theme.spacing_sizes.xs * 8.75}px`,
    height: `${props.theme.spacing_sizes.xs * 8.75}px`,
}));
const DeleteButton = (props) => (_jsx(Button, Object.assign({ variant: 'tinted', color: 'error', size: 'large', fullWidth: true }, props)));
const QuestionIconComponent = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 44,
    width: `${props.theme.spacing_sizes.xs * 8.75}px`,
    height: `${props.theme.spacing_sizes.xs * 8.75}px`,
    borderRadius: '50%',
    color: props.theme.palette.primary.main,
    backgroundColor: '#F8FAFF',
    fontWeight: 600,
    userSelect: 'none',
    cursor: 'default',
}));
const QuestionIcon = () => _jsx(QuestionIconComponent, { children: "?" });
const SecondaryButton = (props) => (_jsx(Button, Object.assign({ variant: 'tinted', color: 'primary', size: 'large', fullWidth: true }, props)));
export { StepTitle, StepForm, PrimaryButton, DeleteButton, SecondaryButton, QuestionIcon, ItemLabel, ElementContainer, StepContainer, CheckmarkIcon, };
